import searchQuery from './search-api.graphql';

export const sendSearchAPI = data=>fetch('https://www.trycaviar.com/graphql?operation=addConsumerAddress', {
  'headers': {
    'accept': '*/*',
    'accept-language': 'en-US',
    'cache-control': 'no-cache',
    'content-type': 'application/json',
    'pragma': 'no-cache',
  },
  'referrerPolicy': 'strict-origin-when-cross-origin',
  'body': JSON.stringify({
    'operationName': 'addConsumerAddress',
    'variables': data,
    'query': searchQuery
  }),
  'method': 'POST',
  'mode': 'cors',
  'credentials': 'include'
});

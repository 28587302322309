declare global{
  interface Window{
    optimizely: any;
    analytics: any;
    gtag: any;
  }
}

export const isBrowser = typeof window !== 'undefined';

if (isBrowser) window.optimizely = window.optimizely || [];

export const segmentTrack = (name: string, extra = {}) => {
  if (!isBrowser) return;
  try{
    const properties = {
      ...extra,
    };
    window.analytics.track(name, properties);
  }catch(e){
    console.error('Failed to track segment event.', e);
  }
};

export const sendEvent = (eventName, tags = {}) => {
  if (!isBrowser) return;
  window.optimizely.push({
    type: 'event',
    eventName,
    tags
  });
  window.gtag('event', eventName, {
    // 'event_label': 'none',
    // 'event_category': 'custom',
    'non_interaction': true
  });
};

import React from 'react';

//See https://underscorejs.org/#throttle
export const throttle = (fn: (...args)=>void, wait = 300) => {
  let inThrottle: boolean,
    lastFn: ReturnType<typeof setTimeout>,
    lastTime: number;
  return function (...args) {
    if (!inThrottle) {
      fn.apply(window, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(window, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export const getDocumentHeight = ()=>{
  return Math.max(document.documentElement.offsetHeight, document.body.offsetHeight);
};

export const getMaxScrollPosition = ()=>{
  return getDocumentHeight() - window.innerHeight;
};

export const getScrollPosition = ()=>{
  // not window.scrollY and window.pageYOffset are the same but the latter has better browser support
  return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
};

//Value is returned as a ratio (1 = 100%)
export const getScrollPercentage = ()=>{
  return getScrollPosition() / getMaxScrollPosition();
};

type ViewportContexValue = {
  width: number;
  height: number;
  scroll: number;
  headerHeight: number;
  setHeaderHeight: (headerHeight: number)=>void
};
const viewportContext = React.createContext<ViewportContexValue>({} as ViewportContexValue);

/* This is from https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/ */
export default function ViewportProvider({ children }: { children: React.ReactNode; }) {
  const [ width, setWidth ] = React.useState(0);
  const [ height, setHeight ] = React.useState(0);
  const [ scroll, setScroll ] = React.useState(0);
  const [ headerHeight, setHeaderHeight ] = React.useState(0);

  const handleWindowResize = throttle(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setScroll(getScrollPosition());
  }, 250);

  const handleWindowScroll = throttle(() => {
    setScroll(getScrollPosition());
  }, 50);

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('scroll', handleWindowScroll);

    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setScroll(getScrollPosition());

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);

  return (
    <viewportContext.Provider value={{ width, height, scroll, headerHeight, setHeaderHeight }}>
      {children}
    </viewportContext.Provider>
  );
}

export const useViewport = () => {
  return React.useContext(viewportContext);
};

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { media } from '../../includes/breakpoints';
import { colors } from '../../includes/style';
import { useGlobalStore } from '../context/StoreProvider';
import { useViewport } from '../context/ViewportProvider';
import Search from '../forms/Search';
import OldBrowserButterBar from '../modals/OldBrowserButterBar';
import Navigation from './Navigation';

const HeaderStyle = styled.header`
  background: white;
  display: flex;
  flex-direction: column;
  transition: top 0.25s linear;
  z-index: 99;

  .sticky-search {
    align-items: center;
    background: #FFFBF0;
    border-radius: 6px;
    box-sizing: border-box;
    display: none;
    max-width: none;
    padding: 13px 18px;

    ${media.tablet`
      margin: 0 auto 22px;
      max-width: 85%;
    `};
  }

  &.prep-sticky {
    position: sticky; top: -200px;
  }

  &.sticky {
    box-shadow: 0 4px 10px -10px rgb(0 0 0 / 60%);
    border-bottom: 1px solid ${colors.silver};
    position: sticky; top: 0;

    .sticky-search {
      display: flex; flex-direction: column; align-items: flex-start;

      ${media.tablet`
        align-items: center;
        flex-direction: row;
      `};
    }

    .navbar-header ul {
      display: none;
    }
  }

  .container {
    padding: 0;
  }
`;

export default function Header(){
  const headerRef = useRef<HTMLDivElement>(null);
  const { width, setHeaderHeight } = useViewport();
  const { prepStickyStatus, stickyStatus } = useGlobalStore();

  /*
    This is ultimately used by ScrollAnchor to determine how much space to leave so the
    sticky navigation bar doesn't cover any content when scrolling to a position on the page.
  */
  useEffect(()=>{
    const elHeader = headerRef.current;
    if (!elHeader) return;
    setHeaderHeight(elHeader.offsetHeight);
  }, [ width ]);

  // for sticky header add class to HeaderStyle
  // className={`${prepStickyStatus ? 'prep-sticky' : ''} ${stickyStatus ? 'sticky' : ''}`}
  return <HeaderStyle id="header" ref={headerRef}>
    <OldBrowserButterBar />
    <Navigation />
    <Search className="sticky-search" />
  </HeaderStyle>;
}

import React, { useState } from 'react';
import styled from 'styled-components';
import CaviarSVG from '../../images/caviar.inline.svg';
import CaviarMobileSVG from '../../images/caviar-mobile.inline.svg';
import { media } from '../../includes/breakpoints';
import { colors } from '../../includes/style';
//import Hamburger from './Hamburger';
import { Link } from 'gatsby';

const NavigationStyle = styled.nav`
  background-color: white;
  color: ${colors.black};

  .object-fit-polyfill{
    position: static !important; //Fixes things on IE11. Not sure why this is necessary
  }

  .container{
    ${media.tablet`
      padding-top: 0; padding-bottom: 0;
    `};

    ${media.large`
      display: flex;
      justify-content: space-evenly;
    `}
  }

  .navbar-header {
    display: flex; align-items: center; justify-content: space-between;
    margin-top: 8px; margin-bottom: 5px;
    width: 100%;

    ${media.tablet`
      margin-top: 20px; margin-bottom: 20px;
    `};
  }

  .logo-desktop {
    display: none;

    ${media.mobile`
      display: block;
    `};
  }

  .logo-mobile {
    ${media.mobile`
      display: none;
    `};
  }

  .navbar-mobile-button{
    ${media.large`
      display: none;
    `};
  }
`;

const MainMenu = styled.ul`
  list-style: none;
  padding: 10px 0 20px;
  margin: 0;

  &[data-menu-open="false"]{
    display: none;
  }
  ${media.large`
    &,
    &[data-menu-open="false"]{
      display: flex;
    }

    justify-content: space-evenly;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    padding: 0;
  `}

  li{
    padding: 0 5px;

    a{
      display: inline-block;
    }
  }

  ${media.desktop`
    justify-content: center;

    li{
      padding: 0 14px;
    }
  `}

  .menu-item-link{
    ${media.large`
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}
  }

  .menu-item-button a{
    border: 1px solid #fff;
    padding: 10px;
    line-height: 1em;
    margin-top: 8px;
    ${media.large`
      margin: 0;
    `}
  }

  a{
    font-weight: 600;
    letter-spacing: 0.05em;
    border: 0;
    text-decoration: none;
    font-size: 12px;
    white-space: nowrap;
  }
`;

export default function Header(){
  const [ isOpen ] = useState(false);

  return <NavigationStyle id="navigation" className="navbar">
    <div className="container">
      <div className="navbar-header">
        <Link className="logo-wrapper" to="https://www.trycaviar.com/">
          <CaviarSVG className="logo-desktop" />
          <CaviarMobileSVG className="logo-mobile" />
        </Link>
      </div>
      <MainMenu id="main-menu" className={`navbar-menu ${isOpen ? 'opened' : 'closed'}`} data-menu-open={isOpen}>
        <li className="menu-item-link">
          {/* <Link to="/city/boston">Boston</Link> */}
        </li>
      </MainMenu>
      {/* <div id="menu-login"></div> */}
    </div>
  </NavigationStyle>;
}

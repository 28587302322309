const searchQuery = `mutation addConsumerAddress(
  $lat: Float!
  $lng: Float!
  $city: String!
  $state: String!
  $zipCode: String!
  $printableAddress: String!
  $shortname: String!
  $googlePlaceId: String!
  $subpremise: String
  $driverInstructions: String
  $dropoffOptionId: String
  $manualLat: Float
  $manualLng: Float
  $addressLinkType: AddressLinkType
) {
  addConsumerAddress(
    lat: $lat
    lng: $lng
    city: $city
    state: $state
    zipCode: $zipCode
    printableAddress: $printableAddress
    shortname: $shortname
    googlePlaceId: $googlePlaceId
    subpremise: $subpremise
    driverInstructions: $driverInstructions
    dropoffOptionId: $dropoffOptionId
    manualLat: $manualLat
    manualLng: $manualLng
    addressLinkType: $addressLinkType
  ) {
    ...ConsumerFragment
    __typename
  }
}

fragment ConsumerFragment on Consumer {
  id
  timezone
  firstName
  lastName
  localizedNames {
    informalName
    formalName
    formalNameAbbreviated
    __typename
  }
  email
  phoneNumber
  phoneNumberVerified
  receiveTextNotifications
  defaultCountry
  isGuest
  scheduledDeliveryTime
  accountCredits
  hasCompletedFirstOrder
  dropoffOptions {
    id
    displayString
    isDefault
    isEnabled
    placeholderText
    disabledMessage
    __typename
  }
  phoneNumberComponents {
    formattedNationalNumber
    nationalNumber
    formattedInternationalNumber
    countryCode
    internationalNumber
    countryShortname
    __typename
  }
  availableAddresses {
    id
    street
    city
    subpremise
    state
    zipCode
    lat
    lng
    manualLat
    manualLng
    shortname
    printableAddress
    driverInstructions
    dropoffPreferences {
      allPreferences {
        optionId
        isDefault
        instructions
        __typename
      }
      __typename
    }
    countryCode
    country
    timezone
    buildingName
    entryCode
    addressLinkType
    __typename
  }
  defaultAddress {
    ...DefaultAddressFragment
    __typename
  }
  districtAvailability {
    isDistrictActive
    __typename
  }
  orderCart {
    ...ConsumerOrderCartFragment
    __typename
  }
  activeSubscription {
    ...SubscriptionFragment
    __typename
  }
  allSubscriptionPlans {
    ...ConsumerSubscriptionPlanFragment
    __typename
  }
  __typename
}

fragment DefaultAddressFragment on DefaultAddress {
  id
  street
  city
  subpremise
  state
  zipCode
  country
  countryCode
  lat
  lng
  manualLat
  manualLng
  timezone
  shortname
  printableAddress
  driverInstructions
  dropoffPreferences {
    allPreferences {
      optionId
      isDefault
      instructions
      __typename
    }
    __typename
  }
  buildingName
  entryCode
  addressLinkType
  __typename
}

fragment ConsumerOrderCartFragment on OrderCart {
  id
  hasError
  isConsumerPickup
  isConvenienceCart
  isMerchantShipping
  offersDelivery
  offersPickup
  subtotal
  urlCode
  groupCart
  groupCartType
  groupCartPollInterval
  isCatering
  isBundle
  cateringInfo {
    cateringVersion
    minOrderSize
    maxOrderSize
    orderInAdvanceInSeconds
    cancelOrderInAdvanceInSeconds
    __typename
  }
  shortenedUrl
  maxIndividualCost
  serviceRateMessage
  isOutsideDeliveryRegion
  currencyCode
  menu {
    id
    hoursToOrderInAdvance
    name
    minOrderSize
    isCatering
    __typename
  }
  creator {
    id
    firstName
    lastName
    localizedNames {
      informalName
      formalName
      formalNameAbbreviated
      __typename
    }
    __typename
  }
  deliveries {
    id
    quotedDeliveryTime
    __typename
  }
  submittedAt
  restaurant {
    id
    name
    maxOrderSize
    coverImgUrl
    slug
    address {
      printableAddress
      street
      lat
      lng
      __typename
    }
    business {
      id
      name
      __typename
    }
    __typename
  }
  storeDisclaimers {
    id
    disclaimerDetailsLink
    disclaimerLinkSubstring
    disclaimerText
    displayTreatment
    __typename
  }
  orders {
    ...ConsumerOrdersFragment
    __typename
  }
  teamAccount {
    id
    name
    __typename
  }
  ...InvalidItemsFragment
  ...ConsumerOrderCartDomainFragment
  __typename
}

fragment InvalidItemsFragment on OrderCart {
  invalidItems {
    itemId
    storeId
    itemQuantityInfo {
      discreteQuantity {
        quantity
        unit
        __typename
      }
      continuousQuantity {
        quantity
        unit
        __typename
      }
      __typename
    }
    name
    itemExtrasList
    menuId
    __typename
  }
  __typename
}

fragment ConsumerOrdersFragment on Order {
  id
  consumer {
    firstName
    lastName
    id
    localizedNames {
      informalName
      formalName
      formalNameAbbreviated
      __typename
    }
    __typename
  }
  isSubCartFinalized
  orderItems {
    id
    options {
      id
      name
      quantity
      nestedOptions
      __typename
    }
    nestedOptions
    specialInstructions
    substitutionPreference
    quantity
    singlePrice
    priceOfTotalQuantity
    continuousQuantity
    unit
    purchaseType
    estimatedPricingDescription
    increment {
      decimalPlaces
      unitAmount
      __typename
    }
    item {
      id
      imageUrl
      name
      price
      minAgeRequirement
      category {
        title
        __typename
      }
      extras {
        id
        title
        description
        __typename
      }
      __typename
    }
    bundleStore {
      id
      name
      isPrimary
      __typename
    }
    __typename
  }
  paymentCard {
    id
    stripeId
    __typename
  }
  paymentLineItems {
    subtotal
    taxAmount
    subtotalTaxAmount
    feesTaxAmount
    serviceFee
    __typename
  }
  __typename
}

fragment ConsumerOrderCartDomainFragment on OrderCart {
  domain {
    giftInfo {
      recipientName
      recipientGivenName
      recipientFamilyName
      recipientPhone
      recipientEmail
      cardMessage
      cardId
      shouldNotifyTrackingToRecipientOnDasherAssign
      shouldNotifyRecipientForDasherQuestions
      senderName
      shouldRecipientScheduleGift
      hasGiftIntent
      __typename
    }
    __typename
  }
  __typename
}

fragment SubscriptionFragment on Subscription {
  subscriptionStatus
  id
  subscriptionPlan {
    isPartnerPlan
    allowAllStores
    id
    numEligibleStores
    __typename
  }
  __typename
}

fragment ConsumerSubscriptionPlanFragment on ConsumerSubscriptionPlan {
  allowAllStores
  id
  numEligibleStores
  isCorporatePlan
  __typename
}`;

export default searchQuery;

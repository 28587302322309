import React from 'react';

//Example data store. This is for data that must be accessible across the whole app.

type StoreContexValue = {
  stickyStatus: boolean,
  setStickyStatus: (status: boolean) => void
  prepStickyStatus: boolean,
  setPrepStickyStatus: (status: boolean) => void
};

const storeContext = React.createContext({} as StoreContexValue);

// eslint-disable-next-line @typescript-eslint/no-empty-function
//const storeContext = React.createContext<StoreContexValue>([defaultStore, ()=>{}]);

/* This is from https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/ */
export default function StoreProvider({ children }: { children: React.ReactNode; }) {
  const [ stickyStatus, setStickyStatus ] = React.useState(false);
  const [ prepStickyStatus, setPrepStickyStatus ] = React.useState(false);

  return (
    <storeContext.Provider value={{
      stickyStatus,
      setStickyStatus,
      prepStickyStatus,
      setPrepStickyStatus,
    }}>
      {children}
    </storeContext.Provider>
  );
}

export const useGlobalStore = () => {
  return React.useContext(storeContext);
};

import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { sendSearchAPI } from '../../includes/search-api';
import LocationSVG from '../../images/location.inline.svg';
import ArrowSVG from '../../images/arrow.inline.svg';
import { media } from '../../includes/breakpoints';

const SearchStyle = styled.div`
  background: white;
  width: 100%;
  margin: 0 auto;

  ${media.mobile`
    max-width: 85%;
  `};

  form.search-location {
    background: #FAFAFA;
    border: 1px solid #C4C4C4;
    box-shadow: 0px 0px 0px #FAFAFA;
    box-sizing: border-box;
    border-radius: 100px;
    display: flex; align-items: center; flex: 1 1 auto;
    padding: 5px;
    width: 100%;

    ${media.tablet`
      margin-left: 15px;
      padding: 8px;
      width: auto;
    `};
  }

  svg {
    margin: 0 8px;
  }

  input {
    background: transparent;
    border: 0;
    flex-grow: 1;

    &:focus-visible {
      outline: none;
    }
  }

  button {
    background: #F86932;
    border: 0;
    border-radius: 100%;
    display: inline-flex; align-items: center; justify-content: center;
    height: 40px;
    width: 40px;
  }

  &.hero-search {
    background: transparent;
    margin: 0;

    span {
      display: none;
    }
  }
`;

const parseAddressObject = (addressObject)=>{
  //This is based on this reference site: https://www.doordash.com/food-delivery/new-york-city-ny-restaurants/
  const address = {
    googlePlaceId: addressObject.place_id,
    city: '',
    state: '',
    zipCode: '',
    street: '',
    shortname: '',
    printableAddress: addressObject.formatted_address,
    lat: addressObject.geometry.location.lat(),
    lng: addressObject.geometry.location.lng(),
    countryCode: 'US',
    addressTrackingData: {
      formattedAddress: addressObject.formatted_address,
      googlePlaceId: addressObject.place_id,
      name: '',
      route: '',
      subpremise: '',
    }
  };
  addressObject.address_components.forEach(({ short_name, long_name, types }) => {
    types.forEach(type => {
      switch (type){
        case 'locality': return address.city = long_name;
        case 'administrative_area_level_1': return address.state = short_name;
        case 'postal_code': return address.zipCode = short_name;
        case 'name': return address.addressTrackingData.name = long_name;
        case 'subpremise': return address.addressTrackingData.subpremise = long_name;
        case 'street_number':
          address.street = long_name + ' ' + address.street;
          address.shortname = short_name + ' ' + address.shortname;
          return;
        case 'route':
          address.addressTrackingData.route = long_name;
          address.street = address.street + long_name + ' ';
          address.shortname = address.shortname + short_name + ' ';
          return;
      }
    });
  });
  address.street = address.street.trim();
  address.shortname = address.shortname.trim();
  return address;
};

function SearchLocationForm() {
  const [ query, setQuery ] = useState('');
  const autoCompleteRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
      componentRestrictions: { country: 'us' },
      fields: ['place_id', 'address_components', 'formatted_address', 'geometry'],
      types: [],
    });
    //change to using the submit button
    //autoComplete.addListener('place_changed', () =>{
    formRef.current?.addEventListener('submit', (e) => {
      e.preventDefault(); //added for submit
      const addressObject = autoComplete.getPlace();
      //console.log(addressObject);
      if (!addressObject || !addressObject.place_id) return;
      const address = parseAddressObject(addressObject);

      sendSearchAPI(address).then(r=>r.json()).then(result=>{
        //console.log('result', result);

        if (result.data){ //looks like success
          // window.location.href = `https://www.trycaviar.com/?newUser=true`;
        }
      });
    });
  }, []);

  return (
    <form className='search-location' ref={formRef}>
      <LocationSVG />
      <input
        ref={autoCompleteRef}
        onChange={event => setQuery(event.target.value)}
        placeholder='Enter your address'
        value={query}
      />
      <button>
        <ArrowSVG />
      </button>
    </form>
  );
}

type SearchProps = {
  children?: ReactNode,
  className?: string
};
export default function Search({ children, className }:SearchProps){
  return (
    <SearchStyle className={className}>
      <span>Enter your address to get food</span>
      <SearchLocationForm />
    </SearchStyle>
  );
}

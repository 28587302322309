/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import StoreProvider from './src/components/context/StoreProvider';
import ViewportProvider from './src/components/context/ViewportProvider';
import Layout from './src/components/Layout';
import { GlobalStyle } from './src/includes/style';

export const wrapRootElement = ({ element }) => {
  return <ViewportProvider><StoreProvider>{element}</StoreProvider></ViewportProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}><GlobalStyle />{element}</Layout>;
};

export const onRouteUpdate = () => {
  //Fire off the Segment tracking call
  window.analytics && window.analytics.page();
};

//Gatsby automatically scrolls back to the top of the page between SPA transitions. This turns it off.
export const shouldUpdateScroll = () => false;

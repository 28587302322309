module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KKCBX7GKJT"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"ctJaPszdHTeUJeaiMgfH5IFNFxo9gTcq","devKey":"ctJaPszdHTeUJeaiMgfH5IFNFxo9gTcq"},
    },{
      plugin: require('../node_modules/@crometrics/gatsby-plugin-optimizely-js/gatsby-browser.js'),
      options: {"plugins":[],"optimizelyId":"19210130816"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Caviar","description":"Order delivery or takeout from the best and premium New York City restaurants. Get breakfast, lunch, dinner and more right to your doorstep with one easy click!","start_url":"/","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"df298d9ca2beb97532b1c56d08bc1d6f"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
